import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/nicholaswarner/dawnstar/MM-W/catalyst/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "submenu-2"
    }}>{`Submenu 2`}</h1>
    <p>{`Just a sub menu page…nothing to see here…or is there??`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      